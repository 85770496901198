import { styled } from "@mui/material/styles";
import { ListItemText as MUIListItemText } from "@mui/material";

export const TopBarContainer = styled("div")`
  width: 100%;
  height: 65px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 10px 20px 0px black;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 5;
  backdrop-filter: blur(3px);
`;

export const PagesContainer = styled("div")`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: inherit;
`;

export const Toolbar = styled("div")`
  padding: 3px 16px;
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 5;
  box-shadow: 0px 10px 20px 0px black;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(3px);
`;

interface ListItemTextProps {
  isActive: boolean;
}

export const ListItemText = styled(MUIListItemText)<ListItemTextProps>`
  text-decoration: ${({ isActive }) => (isActive ? "underline" : "none")};
`;

export const UserContainer = styled("div")`
  position: absolute;
  top: 12px;
  right: 5px;
`;
