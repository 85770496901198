import React, { useState } from "react";
import { navigate } from "gatsby";
import { Menu, MenuItem, ListItemIcon, Icon, IconButton } from "@mui/material";
import { Logout, PermIdentity, Person, Settings } from "@mui/icons-material";
import { useProfileContext } from "../../contexts/profileContext";
import { UserContainer } from "./Styled";
import Cart from "./Cart";
import { routePaths } from "../../constants/routes";

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const profile = useProfileContext();
  const user = profile?.user;
  const logout = profile?.logout;
  const isAdmin = profile?.isAdmin;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    user ? setAnchorEl(event.currentTarget) : navigate(routePaths.login);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <UserContainer>
      <Cart />
      <IconButton
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="secondary"
      >
        {user ? <Person /> : <PermIdentity />}
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigate(routePaths.profile);
            handleClose();
          }}
        >
          <ListItemIcon style={{ minWidth: 30 }}>
            <Person color="secondary" />
          </ListItemIcon>
          Mon compte
        </MenuItem>
        {isAdmin && (
          <MenuItem
            onClick={() => {
              navigate(routePaths.admin.index);
              handleClose();
            }}
          >
            <ListItemIcon style={{ minWidth: 30 }}>
              <Settings color="secondary" />
            </ListItemIcon>
            Admin
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            logout();
            setAnchorEl(null);
            navigate(routePaths.home);
          }}
        >
          <ListItemIcon style={{ minWidth: 30 }}>
            <Logout color="secondary" />
          </ListItemIcon>
          Déconnexion
        </MenuItem>
      </Menu>
    </UserContainer>
  );
};

export default UserMenu;
