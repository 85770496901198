import axios from 'axios'
import { OrderStatus } from '../types'

export const fetchCurrentOrder = async () => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/get-current-order`
    )
    return response?.data || null
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const createOrder = async data => {
  try {
    const response = await axios.post(
      `${process.env.GATSBY_STRAPI_URL}/api/create-order`,
      { data }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const updateOrder = async (id, data) => {
  try {
    const response = await axios.patch(
      `${process.env.GATSBY_STRAPI_URL}/api/update-order`,
      { id, data }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const deleteOrder = async id => {
  try {
    const response = await axios.delete(
      `${process.env.GATSBY_STRAPI_URL}/api/delete-order/${id}`
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const updateAdminOrder = async (id, data) => {
  try {
    const response = await axios.patch(
      `${process.env.GATSBY_STRAPI_URL}/api/admin/update-order`,
      { id, data }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const fetchOrders = async (filters: OrderStatus[]) => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/get-orders`,
      { params: { filters } }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const fetchAdminOrders = async (filters: OrderStatus[]) => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/admin/orders`,
      {
        params: {
          filters
        }
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const fetchAdminUsers = async () => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/users-permissions/admin/users`
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const fetchAdminOrder = async (id: number) => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/admin/orders/${id}`
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const fetchOrder = async (id: number) => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/get-order/${id}`
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}
