/* eslint-disable camelcase */
export interface User {
  id: number
  username: string
  firstName: string
  lastName: string
  email: string
  token: string
  blocked: boolean | null
  confirmed: boolean
  createdAt: string
  provider: string
  updatedAt: string
}

export enum OrderStatus {
  DELIVERED = 'delivered',
  SHIPPING_PROCESS = 'shipping_process',
  COLLECTED = 'collected',
  READY_TO_COLLECT = 'ready_to_collect',
  PROCESSING = 'processing',
  PAYMENT_ACCEPTED = 'payment_accepted',
  UNPAID = 'unpaid'
}

export type ProductQuantities = {
  [productId: string]: number
}

export type StatusHistory = {
  [status: string]: {
    date: Date
    status: string
  }
}

export interface Order {
  id: number
  createdAt: Date
  updatedAt: Date
  publishedAt: Date
  shippingAddress: Address
  billingAddress: Address
  comment: string
  status: OrderStatus
  statusHistory: JSON
  productQuantities: ProductQuantities
  trackCode: string
  products: Product[]
  totalPrice: number
  orderDate: string | null
  deliveryPrice: number
  deliveryMode: DeliveryMode
  details: any
  user?: {
    id: number
    firstName: string
    lastName: string
    email: string
  }
}

export interface Address {
  id: number
  createdAt: string
  updatedAt: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  postalCode: string
  country: string
  company: string
  city: string
  mobilePhone: string
  landlinePhone: string
  otherInformation: string
  addressName: string
}

export interface Role {
  id: string
  name: string
  description: string
  type: string
}

export interface Product {
  id: number
  strapi_id?: number
  created_at: string
  updated_at: string
  title: string
  description: string
  quantity: number
  sold: boolean
  price: number
  promotion: number
  slug: string
  publishedAt: string
  images: UploadFile[]
  categories: Category[]
  deliveryCategory: DeliveryCategories
  deliverable: boolean
}

export interface ToOrder {
  id: string
  created_at: string
  updated_at: string
  title: string
  description: string
  price: number
  published_at: string
  images: UploadFile[]
  categories: Category[]
}

export type UploadFile = {
  id: string
  alternativeText: string
  url: string
}

export interface Category {
  id: string
  created_at: string
  updated_at: string
  title: string
  description: string
  published_at: string
  products: Product[]
}

export interface DeliveryMode {
  id: number
  created_at: string
  updated_at: string
  title: string
  description: string
  published_at: string
  price: number
  logo: UploadFile
  trackUrl: string
  isHomeDelivery: boolean
}

export enum DeliveryCategories {
  XXXS = 'xxxs',
  XXS = 'xxs',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
  XXXL = 'xxxl'
}

export interface Message {
  id: string
  created_at: string
  updated_at: string
  name: string
  email: string
  message: string
  toOrder: ToOrder
}
