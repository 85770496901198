import React from "react";
import { Facebook, Instagram, Pinterest } from "@mui/icons-material";

export const getSocialLogo = (logoName: string) => {
  switch (logoName.toLowerCase()) {
    case "facebook":
      return <Facebook />;
    case "instagram":
      return <Instagram />;
    case "pinterest":
      return <Pinterest />;

    default:
      return null;
  }
};
