import { routePaths } from "../../constants/routes";

export const getPages = ({
  home,
  shop,
  whoIAm,
  renovation,
  gallery,
  toOrder,
  contact,
}) => [
  ...(home.referencing?.metaTitle
    ? [
        {
          to: routePaths.home,
          title: home.referencing?.metaTitle,
        },
      ]
    : []),
  ...(shop.referencing?.metaTitle
    ? [
        {
          to: routePaths.shop,
          title: shop.referencing?.metaTitle,
        },
      ]
    : []),
  ...(toOrder.referencing?.metaTitle
    ? [
        {
          to: routePaths.toOrder,
          title: toOrder.referencing?.metaTitle,
        },
      ]
    : []),
  ...(gallery.referencing?.metaTitle
    ? [
        {
          to: routePaths.gallery,
          title: gallery.referencing?.metaTitle,
        },
      ]
    : []),
  ...(renovation.referencing?.metaTitle
    ? [
        {
          to: routePaths.renovation,
          title: renovation.referencing?.metaTitle,
        },
      ]
    : []),
  ...(whoIAm.referencing?.metaTitle
    ? [
        {
          to: routePaths.whoWeAre,
          title: whoIAm.referencing?.metaTitle,
        },
      ]
    : []),
  ...(contact.referencing?.metaTitle
    ? [
        {
          to: routePaths.contact,
          title: contact.referencing?.metaTitle,
        },
      ]
    : []),
];
