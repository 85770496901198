import React, { useCallback, useEffect, useState } from 'react'
import { Badge, IconButton } from '@mui/material'
import { navigate } from 'gatsby'
import { ShoppingCartOutlined } from '@mui/icons-material'
import { useProfileContext } from '../../contexts/profileContext'
import { routePaths } from '../../constants/routes'
import { getLSValue, LocalStorageKey } from '../../helpers/localStorage'
import { EventClient, useEventClient } from '../../helpers/appClientEvent'

const Cart = () => {
  const order = useProfileContext()?.order
  const [totalProducts, setTotalProducts] = useState(null)

  const getStoredTotalProducts = useCallback(() => {
    const storedOrder = getLSValue(LocalStorageKey.ORDER)
    const productQuantities: number[] = Object.values(
      storedOrder?.productQuantities || {}
    )
    return productQuantities.reduce((acc, value) => acc + value, 0)
  }, [])

  useEffect(() => {
    const productQuantities = order
      ? Object.values(order?.productQuantities || {}).reduce(
          (acc, value) => acc + value,
          0
        )
      : getStoredTotalProducts()
    setTotalProducts(productQuantities)
  }, [order, getStoredTotalProducts])

  useEventClient(EventClient.STORAGE, () => {
    setTotalProducts(getStoredTotalProducts())
  })

  return (
    <IconButton
      onClick={() => navigate(routePaths.shoppingCart)}
      color="secondary"
    >
      <Badge badgeContent={totalProducts} color="primary">
        <ShoppingCartOutlined />
      </Badge>
    </IconButton>
  )
}

export default Cart
