import { Order, OrderStatus, Product, ProductQuantities } from './types'

export const formatStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.PAYMENT_ACCEPTED:
      return 'Paiement accepté !'
    case OrderStatus.DELIVERED:
      return 'Livré !'
    case OrderStatus.PROCESSING:
      return 'En cours de traitement !'
    case OrderStatus.SHIPPING_PROCESS:
      return 'En cours de livraison !'
    case OrderStatus.READY_TO_COLLECT:
      return 'Prêt â être collecté !'
    case OrderStatus.COLLECTED:
      return 'Collecté !'

    default:
      return ''
  }
}

export const formatPrice = (
  price: number,
  displayNull?: boolean,
  decimal = 2
) => {
  return !price && !displayNull
    ? null
    : `${price.toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}€`
}

export const buildImageLink = (url: string) => {
  if (
    process.env.NODE_ENV === 'development' &&
    !process.env.GATSBY_STAGING &&
    !process.env.GATSBY_PRODUCTION
  ) {
    return process.env.GATSBY_STRAPI_URL + url
  }
  return url
}

export const formatEdges = data => data.edges.map(edge => edge.node)

export const formatAttributes = data =>
  Array.isArray(data)
    ? data.map(({ id, attributes }) => ({ id, ...attributes }))
    : { id: data.id, ...data.attributes }

export const getInitialLocaleOrder = (): Order => ({
  id: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  publishedAt: new Date(),
  status: OrderStatus.UNPAID,
  shippingAddress: null,
  billingAddress: null,
  comment: null,
  trackCode: null,
  deliveryMode: null,
  deliveryPrice: null,
  statusHistory: null,
  details: null,
  orderDate: null,
  totalPrice: 0,
  productQuantities: {},
  products: []
})

export const getTotalPrice = (
  products: Product[],
  productQuantities: ProductQuantities
) =>
  products?.reduce((acc, { id, price }) => {
    acc = acc + productQuantities[id] * price
    return acc
  }, 0)
