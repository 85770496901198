import axios from 'axios'

export const fetchMe = async () => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/users/me`
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const updateUser = async data => {
  try {
    const response = await axios.put(
      `${process.env.GATSBY_STRAPI_URL}/api/users-permissions/update-user`,
      data
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

type RegisterProps = {
  email: string
  username: string
  password: string
}

export const register = async ({
  email,
  username,
  password
}: RegisterProps) => {
  try {
    const response = await axios.post(
      `${process.env.GATSBY_STRAPI_URL}/api/auth/local/register`,
      {
        email,
        username,
        password
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

type LoginProps = {
  email: string
  password: string
}

export const login = async ({ email, password }: LoginProps) => {
  try {
    const response = await axios.post(
      `${process.env.GATSBY_STRAPI_URL}/api/auth/local`,
      {
        identifier: email,
        password
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const forgotPassword = async (email: string) => {
  try {
    const response = await axios.post(
      `${process.env.GATSBY_STRAPI_URL}/api/users-permissions/forgot-password`,
      {
        email
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const changePassword = async params => {
  try {
    const response = await axios.post(
      `${process.env.GATSBY_STRAPI_URL}/api/users-permissions/update-password`,
      params
    )
    return response.data
  } catch (e: any) {
    console.error(e)
    throw new Error(e.response.data.error.message || 'Une erreur est survenue')
  }
}

export const resetPassword = async params => {
  try {
    const response = await axios.post(
      `${process.env.GATSBY_STRAPI_URL}/api/users-permissions/reset-password`,
      params
    )
    return response.data
  } catch (e: any) {
    console.error(e)
    throw new Error(e.response.data.error.message || 'Une erreur est survenue')
  }
}
