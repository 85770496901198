import React, { useState } from "react";
import {
  Button,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import {
  TopBarContainer,
  PagesContainer,
  Toolbar,
  ListItemText,
} from "./Styled";
import UserMenu from "./UserMenu";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { getPages } from "./helper";

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    shop: strapiShop {
      referencing {
        metaTitle
      }
    }
    whoIAm: strapiWhoIAm {
      referencing {
        metaTitle
      }
    }
    renovation: strapiRenovation {
      referencing {
        metaTitle
      }
    }
    gallery: strapiGallery {
      referencing {
        metaTitle
      }
    }
    toOrder: strapiToOrder {
      referencing {
        metaTitle
      }
    }
    contact: strapiContactForm {
      referencing {
        metaTitle
      }
    }
  }
`;

const TopBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const data = useStaticQuery(query);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const pages = getPages(data);

  return (
    <>
      {isTablet ? (
        <>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ marginTop: "7px" }}
            >
              <Menu color="primary" />
            </IconButton>
            {process.env.GATSBY_DISPLAY_SHOPPING_OPTIONS && <UserMenu />}
          </Toolbar>
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Icon
              color="inherit"
              aria-label="close drawer"
              onClick={handleDrawerToggle}
              style={{ margin: "16px 0 0 16px" }}
            >
              <Close />
            </Icon>
            <List>
              {pages.map(({ title, to }) => (
                <ListItem button key={title} onClick={() => navigate(`${to}`)}>
                  <ListItemText
                    isActive={
                      typeof window !== "undefined" &&
                      to === window.location.pathname
                    }
                    primary={title}
                  />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </>
      ) : (
        <TopBarContainer>
          <PagesContainer>
            {pages.map(({ title, to }) => (
              <Button
                key={title}
                onClick={() => navigate(`${to}`)}
                variant="outlined"
                color={
                  typeof window !== "undefined" &&
                  to === window.location.pathname
                    ? "secondary"
                    : "primary"
                }
              >
                {title}
              </Button>
            ))}
          </PagesContainer>

          {process.env.GATSBY_DISPLAY_SHOPPING_OPTIONS && <UserMenu />}
        </TopBarContainer>
      )}
    </>
  );
};

export default TopBar;
