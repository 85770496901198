import React from 'react'
import { Stack, Typography } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'

import {
  FooterContainer,
  Image,
  TextContainer,
  SocialContainer,
  SubContainer,
  CopyRight,
  WebsiteLink,
  SocialLink,
  LegalMentions
} from './Styled'
import { routePaths } from '../../constants/routes'
import { getSocialLogo } from './helpers'

const query = graphql`
  query {
    information: strapiInformation {
      address
      postalCode
      city
      name
      email
      strapi_id
      socialLinks {
        title
        url
        color
        logo
        strapi_id
      }
    }
    legalMention: strapiLegalMention {
      text {
        data {
          text
        }
      }
    }
    personalData: strapiPersonalData {
      text {
        data {
          text
        }
      }
    }
    termsOfSale: strapiTermsOfSale {
      text {
        data {
          text
        }
      }
    }
  }
`

const Footer = () => {
  const { information, legalMention, personalData, termsOfSale } =
    useStaticQuery(query)

  const { name, address, postalCode, city, socialLinks, email } = information

  return (
    <FooterContainer>
      <Image src="/footer.jpg" alt="footer.jpg" />
      <SubContainer>
        <TextContainer>
          {name && (
            <Typography variant="body1" mb={2} mt={1}>
              {name}
            </Typography>
          )}
          {address && <Typography variant="body1">{address}</Typography>}
          {postalCode && city && (
            <Typography variant="body1">{`${postalCode} ${city}`}</Typography>
          )}
          {email && <Typography variant="body1">{email}</Typography>}
        </TextContainer>
        <TextContainer>
          {socialLinks.length > 0 && (
            <Typography variant="body1" align="center" mb={2} mt={1}>
              Retrouvez nous aussi sur les réseaux sociaux :
            </Typography>
          )}
          {socialLinks.map(({ strapi_id, url, title, logo, color }) => (
            <SocialLink
              key={strapi_id}
              href={url}
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <SocialContainer color={color}>
                {getSocialLogo(logo)} <Typography>{title}</Typography>
              </SocialContainer>
            </SocialLink>
          ))}
        </TextContainer>
      </SubContainer>
      <CopyRight>
        <Typography>{`Copyright © ${new Date().getFullYear()} ${name}. Tous droits réservés`}</Typography>
      </CopyRight>
      <CopyRight>
        <Typography>
          {`Créé par `}
          <WebsiteLink href="https://www.maximepochet.fr" target="_blank">
            Maxime Pochet
          </WebsiteLink>
        </Typography>
      </CopyRight>
      <Stack
        direction="row"
        gap={2}
        position="relative"
        justifyContent="center"
      >
        {!!legalMention?.text?.data?.text && (
          <Typography>
            <LegalMentions to={routePaths.legalMentions}>
              Mentions légales
            </LegalMentions>
          </Typography>
        )}
        {!!legalMention?.text?.data?.text &&
          !!personalData?.text?.data?.text && <Typography>-</Typography>}
        {!!personalData?.text?.data?.text && (
          <Typography>
            <LegalMentions to={routePaths.privacyPolicy}>
              Politique de confidentialité
            </LegalMentions>
          </Typography>
        )}
        {!!termsOfSale?.text?.data?.text &&
          !!personalData?.text?.data?.text && <Typography>-</Typography>}
        {!!termsOfSale?.text?.data?.text && (
          <Typography>
            <LegalMentions to={routePaths.termsOfSale}>
              Condition générales de ventes
            </LegalMentions>
          </Typography>
        )}
      </Stack>
    </FooterContainer>
  )
}

export default Footer
