import { useEffect } from "react";
import events from "events";

export enum EventClient {
  STORAGE = "storage",
  UPDATE_AFTER_LOGIN = "updateAfterLogin",
}

const eventEmitter = new events.EventEmitter();

export const useEventClient = (event: any, callback: any) => {
  useEffect(() => {
    eventEmitter.addListener(event, callback);
    return () => {
      eventEmitter.removeListener(event, callback);
    };
  }, [event, callback]);
};

export const emitEventClient = (event: any, args?: any) => {
  eventEmitter.emit(event, args);
};
