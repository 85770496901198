import React from 'react'
import type { GatsbySSR } from 'gatsby'
import { ThemeProvider, GlobalStyles } from '@mui/material'

import { AppContextProvider } from './src/contexts/appContext'
import { ProfileContextProvider } from './src/contexts/profileContext'
import theme from './src/theme'
import Layout from './src/layout'

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      html: {
        scrollBehavior: 'smooth'
      },
      body: {
        margin: 0,
        backgroundColor: theme.palette.background.default
      },
      '.material-icons': {
        fontFamily: 'Material Icons',
        fontSize: '24px'
      },
      '.react-images__view-image': {
        maxWidth: '85vw !important',
        maxHeight: '85vh !important'
      }
    }}
  />
)

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element }) => (
  <ThemeProvider theme={theme}>
    {inputGlobalStyles}
    <AppContextProvider>
      <ProfileContextProvider>
        <Layout>{element}</Layout>
      </ProfileContextProvider>
    </AppContextProvider>
  </ThemeProvider>
)
