import { useTheme, useMediaQuery, Snackbar } from "@mui/material";
import React, { FC, useContext, useReducer } from "react";
import Alert from "../../components/common/Alert";

interface QueryState {
  open: boolean;
  type: any;
  message: string;
}

interface AppContextType {
  showSnackbar: (type: "success" | "error", message?: string) => void;
}

const initialQueryState = {
  open: false,
  message: "",
  type: "success",
};

type QueryStateReducer = (state: any, action: Partial<QueryState>) => any;

export const AppContext = React.createContext<AppContextType | null>(null);

export interface AppContextProviderProps {
  children: React.ReactNode;
}

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [state, dispatch] = useReducer<QueryStateReducer>(
    (state, action) => ({ ...state, ...action }),
    initialQueryState
  );

  const handleClose = () => dispatch({ open: false });

  const showSnackbar = (type: "success" | "error", message?: string) => {
    dispatch({
      open: true,
      type,
      message: message || "Une erreur s'est produite, veuillez réessayer",
    });
  };

  return (
    <AppContext.Provider
      value={{
        showSnackbar,
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={state.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <div>
          <Alert onClose={handleClose} severity={state.type}>
            {state.message}
          </Alert>
        </div>
      </Snackbar>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const app = useContext(AppContext);

  return app;
};

export function withApp<T = any>(Component: FC<T>): FC<T> {
  return (props) => {
    const appObject = useAppContext();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} {...appObject} />;
  };
}
