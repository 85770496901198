import React, { ReactElement } from 'react'
import moment from 'moment'

import { Container } from './Styled'
import TopBar from './TopBar'
import Footer from './Footer'

import 'moment/locale/fr'

interface LayoutProps {
  children: ReactElement
}

moment.locale('fr')

const Layout = ({ children }: LayoutProps) => (
  <>
    <TopBar />
    <Container>{children}</Container>
    <Footer />
  </>
)

export default Layout
