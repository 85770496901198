export enum SessionStorageKey {
  USER = "user",
}

export const setSSValue = (key: SessionStorageKey, value: any) => {
  let valueToSet = value;
  if (typeof value !== "string") {
    valueToSet = JSON.stringify(value);
  }
  // eslint-disable-next-line no-unused-expressions
  typeof window !== "undefined" &&
    window.sessionStorage.setItem(key, valueToSet);
};

export const getSSValue = (key: SessionStorageKey): any => {
  const SSValue =
    typeof window !== "undefined" && window.sessionStorage.getItem(key);
  if (SSValue === "true") return true;
  if (SSValue === "false") return false;
  if (typeof SSValue === "string") return JSON.parse(SSValue);
  return null;
};

export const removeSSValue = (key: SessionStorageKey): any => {
  window.sessionStorage.removeItem(key);
};
