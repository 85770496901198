export enum LocalStorageKey {
  ORDER = "order",
}

export const setLSValue = (key: LocalStorageKey, value: any) => {
  let valueToSet = value;
  if (typeof value !== "string") {
    valueToSet = JSON.stringify(value);
  }
  // eslint-disable-next-line no-unused-expressions
  typeof window !== "undefined" && window.localStorage.setItem(key, valueToSet);
};

export const getLSValue = (key: LocalStorageKey): any => {
  const LSValue =
    typeof window !== "undefined" && window.localStorage.getItem(key);
  if (LSValue === "true") return true;
  if (LSValue === "false") return false;
  if (typeof LSValue === "string") return JSON.parse(LSValue);
  return null;
};

export const removeLSValue = (key: LocalStorageKey): any => {
  window.localStorage.removeItem(key);
};
