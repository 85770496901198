export const routePaths = {
  home: '/',
  renovation: '/renovation',
  contact: '/contact/',
  shop: '/shop/',
  toOrder: '/to-order/',
  whoWeAre: '/who-we-are/',
  product: '/product/:id/',
  gallery: '/gallery/',
  shoppingCart: '/shopping-cart/',
  addresses: '/addresses/',
  address: '/address/',
  password: '/password/',
  orders: '/orders/',
  order: '/order/',
  identity: '/identity/',
  profile: '/profile/',
  termsOfSale: '/terms-of-sale/',
  login: '/login/',
  legalMentions: '/legal-mentions/',
  privacyPolicy: '/privacy-policy/',
  admin: {
    index: '/admin/',
    orders: '/admin/orders/',
    order: '/admin/order/',
    users: '/admin/users/',
    contacts: '/admin/contacts/'
  }
}
