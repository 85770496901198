import axios from 'axios'

export const fetchProducts = async (ids: number[]) => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/get-products`,
      { params: { ids } }
    )
    return response?.data || null
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const fetchProduct = async (id: number) => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/get-product`,
      { params: { id } }
    )
    return response?.data || null
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}
