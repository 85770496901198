import { styled } from "@mui/material/styles";
import { Link } from "gatsby";

export const FooterContainer = styled("div")`
  width: 100vw;
  height: 302px;
  position: fixed;
  bottom: 0;
  z-index: -1;
`;

export const SubContainer = styled("div")`
  display: flex;
  justify-content: space-around;
`;

export const CopyRight = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;
  color: white;
  margin: 5px 0;
`;

export const Image = styled("img")`
  position: absolute;
  width: 102%;
  left: -1px;
  bottom: -1px;
  height: inherit;
  opacity: 0.5;
`;

export const TextContainer = styled("div")`
  position: relative;
  margin: 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50%;
  color: white;
`;

export const SocialContainer = styled("div")(({ theme, color }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  textDecoration: "none",
  color: theme.palette.text.primary,
  "&:hover": {
    color: color,
    cursor: "pointer",
    p: {
      color: color,
    },
    img: {
      filter: "grayscale(0%)",
    },
  },
  img: {
    filter: "grayscale(100%)",
  },
}));

export const SocialLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

export const WebsiteLink = styled("a")`
  text-decoration: none;
  color: white;
  &:hover {
    color: red;
  }
`;

export const LegalMentions = styled(Link)(({ theme }) => ({
  color: "white",
  textDecoration: "none",
  " &:hover": {
    color: theme.palette.primary.main,
  },
}));
