exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-address-tsx": () => import("./../../../src/pages/address.tsx" /* webpackChunkName: "component---src-pages-address-tsx" */),
  "component---src-pages-addresses-tsx": () => import("./../../../src/pages/addresses.tsx" /* webpackChunkName: "component---src-pages-addresses-tsx" */),
  "component---src-pages-admin-contacts-tsx": () => import("./../../../src/pages/admin/contacts.tsx" /* webpackChunkName: "component---src-pages-admin-contacts-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-order-tsx": () => import("./../../../src/pages/admin/order.tsx" /* webpackChunkName: "component---src-pages-admin-order-tsx" */),
  "component---src-pages-admin-orders-tsx": () => import("./../../../src/pages/admin/orders.tsx" /* webpackChunkName: "component---src-pages-admin-orders-tsx" */),
  "component---src-pages-admin-users-tsx": () => import("./../../../src/pages/admin/users.tsx" /* webpackChunkName: "component---src-pages-admin-users-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-identity-tsx": () => import("./../../../src/pages/identity.tsx" /* webpackChunkName: "component---src-pages-identity-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-mentions-tsx": () => import("./../../../src/pages/legal-mentions.tsx" /* webpackChunkName: "component---src-pages-legal-mentions-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-password-tsx": () => import("./../../../src/pages/password.tsx" /* webpackChunkName: "component---src-pages-password-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-renovation-tsx": () => import("./../../../src/pages/renovation.tsx" /* webpackChunkName: "component---src-pages-renovation-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-shopping-cart-tsx": () => import("./../../../src/pages/shopping-cart.tsx" /* webpackChunkName: "component---src-pages-shopping-cart-tsx" */),
  "component---src-pages-terms-of-sale-tsx": () => import("./../../../src/pages/terms-of-sale.tsx" /* webpackChunkName: "component---src-pages-terms-of-sale-tsx" */),
  "component---src-pages-to-order-tsx": () => import("./../../../src/pages/to-order.tsx" /* webpackChunkName: "component---src-pages-to-order-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */),
  "component---src-templates-product-index-tsx": () => import("./../../../src/templates/product/index.tsx" /* webpackChunkName: "component---src-templates-product-index-tsx" */)
}

