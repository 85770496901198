import { styled } from "@mui/material/styles";

export const Container = styled("div")(({ theme }) => ({
  height: "100%",
  margin: "25px 0 300px 0",
  paddingBottom: "25px",
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 5px 10px 0px black",
  [theme.breakpoints.down("sm")]: {
    paddingRight: "16px",
    paddingLeft: "16px",
  },
}));
