import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/material/locale";

const theme = createTheme(
  {
    typography: {
      htmlFontSize: 17,
      fontFamily: ["Oswald", "Fredericka the Great", "sans-serif"].join(","),
      h2: {
        fontSize: 30,
        margin: "20px 0",
        fontFamily: "Fredericka the Great",
        color: "#dddddd",
      },
    },
    palette: {
      primary: {
        main: "#c78836",
      },
      secondary: {
        main: "#ffffff",
      },
      background: {
        paper: "#333",
        default: "#000",
      },
      text: {
        primary: "#dddddd",
        secondary: "rgb(157,157,157)",
      },
      action: {
        disabledBackground: "rgba(255,255,255, 0.5)",
        disabled: "rgba(255,255,255, 0.5)",
      },
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          stickyHeader: {
            backgroundColor: "#333",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&$disabled": {
              color: "rgba(255,255,255, 0.5)",
              backgroundColor: "rgba(255,255,255, 0.5)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "white",
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            border: "1px solid #c7883670",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: 240,
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: "gray",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#dddddd",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          clearIndicator: {
            color: "white",
          },
          listbox: {
            maxHeight: "250px",
          },
        },
      },
    },
  },
  frFR
);

export default theme;
