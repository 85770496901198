import React, { FC, useContext, useState, useEffect } from 'react'
// eslint-disable-next-line import/no-unresolved
import { globalHistory } from '@reach/router'
import { navigate } from 'gatsby-link'
import axios from 'axios'
import isEmpty from 'lodash.isempty'
import {
  getLSValue,
  LocalStorageKey,
  removeLSValue
} from '../../helpers/localStorage'
import { routePaths } from '../../constants/routes'
import {
  getSSValue,
  SessionStorageKey,
  setSSValue,
  removeSSValue
} from '../../helpers/sessionStorage'
import { Order, Product, User } from '../../helpers/types'
// eslint-disable-next-line import/no-cycle
import { useAppContext } from '../appContext'
import {
  register,
  updateUser,
  login,
  changePassword,
  fetchMe
} from '../../helpers/api/user'
import {
  HandleLoginPayload,
  HandleRegisterPayload,
  HandleUpdateUserPayload,
  ProfileContextProviderProps,
  ProfileContextType,
  HandleUpdatePasswordPayload,
  HandleUpdateOrderPayload,
  HandleCreateOrderPayload
} from './type'
import {
  createOrder,
  deleteOrder,
  fetchCurrentOrder,
  updateOrder
} from '../../helpers/api/order'
import { fetchProducts } from '../../helpers/api/products'
import { getInitialLocaleOrder, getTotalPrice } from '../../helpers'

export const ProfileContext = React.createContext<ProfileContextType | null>(
  null
)

export const ProfileContextProvider = ({
  children
}: ProfileContextProviderProps) => {
  const [user, setUser] = useState<User>(null)
  const [order, setOrder] = useState<Order>(undefined)
  const [isAdmin, setIsAdmin] = useState(false)
  const showSnackbar = useAppContext()?.showSnackbar

  const handleLogin = async (user: HandleLoginPayload) => {
    try {
      const { email, password } = user
      const newUser = await login({ email, password })
      axios.defaults.headers.common.Authorization = `Bearer ${newUser.jwt}`
      const me = await fetchMe()

      setUser({ ...me, token: newUser.jwt })
      setSSValue(SessionStorageKey.USER, {
        ...me,
        token: newUser.jwt
      })
      setIsAdmin(me?.role?.type === 'admin')

      const storedOrder = getLSValue(LocalStorageKey.ORDER)
      if (storedOrder) {
        const currentOrder = await getOrder()
        if (currentOrder) {
          await handleUpdateOrder(
            {
              productQuantities: storedOrder.productQuantities,
              products: storedOrder.products
            },
            currentOrder.id
          )
        } else {
          await handleCreateOrder({
            user: me.id,
            productQuantities: storedOrder.productQuantities,
            products: storedOrder.products
          })
        }
        removeLSValue(LocalStorageKey.ORDER)
      }

      if (
        typeof window !== 'undefined' &&
        window.location.pathname === routePaths.login
      )
        navigate(routePaths.home)
    } catch (e) {
      console.error(e)
      showSnackbar('error', 'Erreur lors de la connexion')
    }
  }

  useEffect(() => {
    const checkAccess = (route: string) => {
      const localUser = getSSValue(SessionStorageKey.USER)
      if (!!route.match(`admin`) && localUser?.role?.type !== 'admin')
        navigate(routePaths.home)
    }

    // globalHistory.listen(({ location }) => {
    //   checkAccess(location.pathname)
    // })
    // checkAccess(window.location.pathname)
  }, [])

  const getUser = async (): Promise<User | null> => {
    const localUser = getSSValue(SessionStorageKey.USER)
    if (localUser?.token) {
      try {
        axios.defaults.headers.common.Authorization = `Bearer ${localUser.token}`
        const me = await fetchMe()
        setUser(me)
        setIsAdmin(me?.role?.type === 'admin')
        return {
          ...me,
          token: localUser?.token
        }
      } catch (e) {
        console.error(e)
        removeSSValue(SessionStorageKey.USER)
        axios.defaults.headers.common.Authorization = undefined
      }
    }
    axios.defaults.headers.common.Authorization = undefined
    return null
  }

  const getOrder = async () => {
    const result = await fetchCurrentOrder()
    setOrder(result)
    return result
  }

  useEffect(() => {
    if (!user) {
      const storedOrder = getLSValue(LocalStorageKey.ORDER)
      if (storedOrder && storedOrder.products) {
        const getStoredProducts = async () => {
          const products: Product[] = await fetchProducts(
            storedOrder?.products.map(id => parseInt(id, 10))
          )
          const productQuantities = products?.reduce(
            (acc, { id, quantity }) => {
              if (quantity < storedOrder.productQuantities[id])
                acc[id] = quantity
              acc[id] = storedOrder.productQuantities[id]
              return acc
            },
            {}
          )
          const totalPrice = getTotalPrice(products, productQuantities)
          setOrder({
            ...getInitialLocaleOrder(),
            productQuantities,
            products,
            totalPrice
          })
        }
        getStoredProducts()
      }
      getUser()
    }
  }, [user])

  const handleLogout = () => {
    removeSSValue(SessionStorageKey.USER)
    axios.defaults.headers.common.Authorization = undefined
    setUser(null)
    setOrder(null)
  }

  const handleRegister = async (user: HandleRegisterPayload) => {
    try {
      const newUser = await register({
        username: `${user.firstName} ${user.lastName}`,
        email: user.email,
        password: user.password
      })

      const userObject = {
        ...newUser.user,
        username: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.lastName,
        token: newUser.jwt
      }

      axios.defaults.headers.common.Authorization = `Bearer ${newUser.jwt}`

      await updateUser({
        userId: newUser.user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        username: `${user.firstName} ${user.lastName}`
      })

      const storedOrder = getLSValue(LocalStorageKey.ORDER)
      if (storedOrder) {
        await handleCreateOrder({
          user: newUser.user.id,
          productQuantities: storedOrder.productQuantities,
          products: storedOrder.products
        })
      }

      setUser(userObject)
      setSSValue(SessionStorageKey.USER, userObject)
      removeLSValue(LocalStorageKey.ORDER)
      showSnackbar('success', 'Compte créé avec succès')
    } catch (e) {
      console.error(e)
      showSnackbar('error', "Erreur lors de l'inscription")
    }
  }

  const handleUpdateUser = async (data: HandleUpdateUserPayload) => {
    const username = `${data.firstName} ${data.lastName}`
    await updateUser({
      ...data,
      userId: user.id,
      username
    })
    setUser(prevState => ({
      ...prevState,
      ...data,
      username
    }))
  }

  const handleUpdatePassword = async (data: HandleUpdatePasswordPayload) => {
    const result = await changePassword({
      userId: user.id,
      ...data
    })
    setUser(prevState => ({
      ...prevState,
      token: result.jwt
    }))
  }

  const handleCreateOrder = async (data: HandleCreateOrderPayload) => {
    const result: Order = await createOrder(data)
    setOrder(result)
  }

  const handleUpdateOrder = async (
    data: HandleUpdateOrderPayload,
    id?: number
  ) => {
    if (
      data.productQuantities &&
      data.products &&
      isEmpty(data.productQuantities) &&
      isEmpty(data.products)
    ) {
      await deleteOrder(order.id)
      setOrder(null)
    } else {
      const result: Order = await updateOrder(id || order.id, data)
      if (result.status === 'unpaid') setOrder(result)
    }
  }

  const handleUpdateLocaleOrder = (data: Partial<Order>) => {
    setOrder(prevState => ({
      ...prevState,
      ...data
    }))
  }

  const handleResetOrder = () => setOrder(null)

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    if (user?.id && !order) getOrder()
  }, [user?.id, order])

  return (
    <ProfileContext.Provider
      value={{
        user,
        order,
        getUser,
        getOrder,
        login: handleLogin,
        logout: handleLogout,
        register: handleRegister,
        updateUser: handleUpdateUser,
        updatePassword: handleUpdatePassword,
        createOrder: handleCreateOrder,
        updateOrder: handleUpdateOrder,
        updateLocaleOrder: handleUpdateLocaleOrder,
        resetOrder: handleResetOrder,
        isAdmin
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfileContext = () => {
  return useContext(ProfileContext)
}

export function withProfile<T = any>(
  Component: FC<T & ProfileContextType>
): FC<T> {
  return props => {
    const profileObject = useProfileContext()
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} {...profileObject} />
  }
}
